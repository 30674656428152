import Axios from "axios";

export const searchGamiApi = ({ authToken, deviceId, userId, merchantId, search, page, size }) => {
	if (authToken != null) {
		return Axios({
			method: "get",
			url: window.ApiURL + "v2/gamifications/",
			params: {
				// userId: userId,
				merchantId: merchantId,
				// typeName: "",
				// gameName: search,
				// page: page,
				// size: size,
				// nRecords: 10,
			},
			data: {},
			headers: {
				authToken: authToken,
				// deviceUniqueId: deviceId,
			},
		});
	}
};

export const createGamiApi = ({ authToken, deviceId, userId, merchantId, data }) => {
	if (authToken) {
		return Axios({
			method: "post",
			url: `${window.ApiURL}v2/gamifications/`,
			data: { ...data },
			params: { merchantId },
			headers: { authToken, deviceUniqueId: deviceId },
		});
	}
};

const createUpdateGameApi = ({
	authToken,
	deviceId,
	userId,
	merchantId,
	data,
	gameId,
	id,
	gameType,
}) => {
	return Axios({
		method: id ? "put" : "post",
		url: id
			? `${window.ApiURL}v2/gamifications/${gameType}`
			: `${window.ApiURL}v2/gamifications/${gameType}/new`,
		data: {
			// userId,
			// merchantId,
			...data,
		},
		...(gameType !== "spinners" ? { params: { merchantId } } : { params: { merchantId, id } }),
		headers: {
			"Content-Type": "application/json",
			authToken,
			deviceUniqueId: deviceId,
			gameId: id ? id : gameId,
		},
	});
};

export const createSurveyApi = ({ authToken, deviceId, userId, merchantId, data, gameId }) =>
	createUpdateGameApi({
		authToken,
		deviceId,
		userId,
		merchantId,
		data,
		gameId,
		gameType: "surveys",
	});

export const updateSurveyApi = ({ authToken, deviceId, userId, merchantId, data, gameId, id }) =>
	createUpdateGameApi({
		authToken,
		deviceId,
		userId,
		merchantId,
		data,
		gameId,
		id,
		gameType: "surveys",
	});

export const createQuizApi = ({ authToken, deviceId, userId, merchantId, data, gameId }) =>
	createUpdateGameApi({
		authToken,
		deviceId,
		userId,
		merchantId,
		data,
		gameId,
		gameType: "quizzes",
	});

export const updateQuizApi = ({ authToken, deviceId, userId, merchantId, data, gameId, id }) =>
	createUpdateGameApi({
		authToken,
		deviceId,
		userId,
		merchantId,
		data,
		gameId,
		id,
		gameType: "quizzes",
	});

export const createSpinnerApi = ({ authToken, deviceId, userId, merchantId, data }) =>
	createUpdateGameApi({
		authToken,
		deviceId,
		userId,
		merchantId,
		data,
		gameType: "spinners",
	});

export const updateSpinnerApi = ({ authToken, deviceId, userId, merchantId, data, id }) =>
	createUpdateGameApi({
		authToken,
		deviceId,
		userId,
		merchantId,
		data,
		id,
		gameType: "spinners",
	});

export const deleteGamificationApi = ({
	authToken,
	deviceId,
	userId,
	merchantId,
	id,
	gameType,
}) => {
	if (authToken != null) {
		return Axios({
			method: "delete",
			url: window.ApiURL + `v2/gamifications/${id}`,
			data: {},
			params: {
				// id,
				// gameType,
				// userId,
				merchantId,
			},
			headers: {
				"Content-Type": "application/json",
				authToken: authToken,
				deviceUniqueId: deviceId,
				gameId: id,
			},
		});
	}
};

export const getGameTypesApi = ({ authToken, deviceId, userId, merchantId }) => {
	if (authToken) {
		return Axios({
			method: "get",
			url: `${window.ApiURL}v2/gamifications/types/`,
			params: { merchantId, userId },
			headers: { authToken, deviceUniqueId: deviceId },
		});
	}
};

export const viewGameApi = ({ authToken, deviceId, userId, merchantId, gameId }) => {
	return Axios({
		method: "get",
		url: `${window.ApiURL}v2/gamifications/${userId}`,
		params: { merchantId },
		headers: { authToken, deviceUniqueId: deviceId },
	});
};

export const getRewardPointsApi = ({ authToken, deviceId, merchantId }) => {
	if (authToken) {
		return Axios({
			method: "get",
			url: `${window.ApiURL}v2/gamifications/rewards/`,
			params: { merchantId },
			headers: { authToken, deviceUniqueId: deviceId },
		});
	}
};
