import Axios from 'axios';

export const inquiryOrPostingAddPointApi = (data) => {
    if (data.authToken != null) {
        return Axios({
            method: 'post',
            url: window.ApiURL + data.api,
            data: {
                userId: data.userId,
                memberId: data.memberId,
                merchantId: data.merchantId,
                storeId: data.storeId,
                refId: data.refId,
                basic : data.basic,
                type : 'POS'
            },
            headers: {
                'Content-Type': 'application/json',
                'authToken': data.authToken,
                'deviceUniqueId': data.deviceId
            }
        })
    }
}

