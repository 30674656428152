import {
    RESET_STATUS,
    INQ_ADDPOINT,
    INQ_ADDPOINT_SUCCESS,
    INQ_ADDPOINT_FAILED,
    POST_ADDPOINT,
    POST_ADDPOINT_SUCCESS,
    POST_ADDPOINT_FAILED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    isOpenData: false,
    addPointSuccess: false,
    addPointFailed: false,
    dataInq:{
        userId: "",
        memberId: "",
        merchantId: "",
        storeId : "",
        refId : "",
        basic : {
            productCode : "",
            totalTrxAmount: "",
            storeId: ""
        }
    },
    addPointData: {
        userId: "",
        memberId: "",
        merchantId: "",
        storeId : "",
        refId : "",
        basic : {
            productCode : "",
            totalTrxAmount: "",
            storeId: ""
        }
    },
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case INQ_ADDPOINT: {
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: ''
            }
        }

        case POST_ADDPOINT: {
            return {
                ...state,
                loader: true,
                showMessage: false,
                alertMessage: ''
            }
        }

        case RESET_STATUS : {
            return {
                ...state,
                addPointSuccess: false,
                addPointFailed : false,
                addPointData: {
                    userId: "",
                    memberId: "",
                    merchantId: "",
                    storeId : "",
                    refId : "",
                    basic : {
                        productCode : "",
                        totalTrxAmount: "",
                        storeId: ""
                    }
                },
                dataInq: {
                    userId: "",
                    memberId: "",
                    merchantId: "",
                    storeId : "",
                    refId : "",
                    basic : {
                        productCode : "",
                        totalTrxAmount: "",
                        storeId: ""
                    }
                }
            }
        }

        // Response
        case INQ_ADDPOINT_SUCCESS: {
            return {
                ...state,
                loader: false,
                dataInq: action.payload,
            }

        }

        case INQ_ADDPOINT_FAILED: {
            return {
                ...state,
                loader: false,
                showMessage: true,
                alertMessage: action.payload,
                dataInq: action.payload,
            }
        }
        
        case POST_ADDPOINT_SUCCESS: {
            return {
                ...state,
                loader: false,
                addPointSuccess: true,
                addPointFailed: false,
                addPointData : action.payload,
            }

        }

        case POST_ADDPOINT_FAILED: {
            return {
                ...state,
                loader: false,
                alertMessage: action.payload,
                addPointData: {
                    userId: "",
                    memberId: "",
                    merchantId: "",
                    storeId : "",
                    refId : "",
                    basic : {
                        productCode : "",
                        totalTrxAmount: "",
                        storeId: ""
                    }
                },
                showMessage: true,
                addPointSuccess: false,
                addPointFailed: true,
            }
        }

        default:
            return state;
    }
}
