import Axios from 'axios'

export const viewBuilderApi = ({authToken, deviceId, userId, merchantId, type, builderId}) => {
    if(authToken != null){
        return Axios({
            method: 'get',
            url: window.ApiURL + 'builder-template/view',
            params: {
                userId : userId,
                merchantId : merchantId,
                type: type,
                builderTemplateId: builderId
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

export const searchBuilderApi = ({authToken, deviceId, userId, merchantId}) => {
    if(authToken != null){
        return Axios({
            method: 'get',
            url: window.ApiURL + 'builder-template/search',
            params: {
                userId : userId,
                merchantId : merchantId
            },
            data: {},
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

export const createBuilderApi = ({authToken, deviceId, userId, merchantId, data}) => {
    if(authToken != null){
        // console.log(data)
        return Axios({
            method: 'post',
            url: window.ApiURL + 'builder-template/create',
            data: {
                userId: userId,
                merchantId: merchantId,
                builderTemplate: {
                    backgroundColor: data.backgroundColor,
                    headerColor: data.headerColor,
                    footerColor: data.footerColor,
                    fontColor: data.fontColor,
                    buttonColor: data.buttonColor,
                    description: data.description,
                    pathLogo: data.pathLogo,
                    type: data.type,
                    appName: data.appName
                },
                builderTemplatePhotoList: data.photo
            },
            headers:{
                'Content-Type': 'application/json',
                'authToken': authToken,
                'deviceUniqueId' : deviceId
            }
        });
    };
};

export const updateBuilderApi = ({authToken, deviceId, userId, merchantId, data}) => {
    // console.log(data)
    return Axios({
        method: 'post',
        url: window.ApiURL + 'builder-template/update',
        data: {
            userId: userId,
            merchantId: merchantId,
            builderTemplate: {
                builderTemplateId: data.builderTemplateId,
                backgroundColor: data.backgroundColor,
                headerColor: data.headerColor,
                footerColor: data.footerColor,
                fontColor: data.fontColor,
                buttonColor: data.buttonColor,
                description: data.description,
                pathLogo: data.pathLogo,
                appName: data.appName
            },
            builderTemplatePhotoList: data.photo
        },
        headers:{
            'Content-Type': 'application/json',
            'authToken': authToken,
            'deviceUniqueId' : deviceId
        }
    });
};