import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	SEARCH_GAMIFICATION,
	SEARCH_GAMIFICATION_SUCCESS,
	SEARCH_GAMIFICATION_FAILED,
	VIEW_GAMIFICATION,
	VIEW_GAMIFICATION_SUCCESS,
	VIEW_GAMIFICATION_FAILED,
	DELETE_GAMIFICATION,
	DELETE_GAMIFICATION_SUCCESS,
	DELETE_GAMIFICATION_FAILED,
	BACK_TO_LOGIN,
	GET_GAMIFICATION_TYPES,
	GET_GAMIFICATION_TYPES_SUCCESS,
	GET_GAMIFICATION_TYPES_FAILED,
	GET_REWARD,
	GET_REWARD_SUCCESS,
	GET_REWARD_FAILED,
	CREATE_GAMIFICATION,
	CREATE_GAMIFICATION_SUCCESS,
	CREATE_GAMIFICATION_FAILED,
} from "constants/ActionTypes";
import {
	searchGamiApi,
	viewGameApi,
	createGamiApi,
	deleteGamificationApi,
	getGameTypesApi,
	getRewardPointsApi,
} from "../../appRedux/api/Gamification";

export function* fetchSearchGami({ payload }) {
	if (payload != null) {
		try {
			const searchGamificationData = yield call(searchGamiApi, payload);
			if (searchGamificationData.data.abstractResponse.responseStatus === "INQ000") {
				yield put({
					type: SEARCH_GAMIFICATION_SUCCESS,
					payload: searchGamificationData.data,
				});
			} else if (searchGamificationData.data.abstractResponse.responseStatus !== "ERROR016") {
				let resp = {
					gamification: [],
					recordInfo: {},
				};
				yield put({ type: SEARCH_GAMIFICATION_SUCCESS, payload: resp });
			} else {
				yield put({
					type: SEARCH_GAMIFICATION_FAILED,
					payload: searchGamificationData.data.message,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: SEARCH_GAMIFICATION_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: SEARCH_GAMIFICATION_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchViewGami({ payload }) {
	if (payload != null) {
		try {
			let viewGamificationData = yield call(viewGameApi, payload);
			if (
				viewGamificationData.data.abstractResponse.responseStatus === "INQ000" &&
				viewGamificationData.data.data
			) {
				yield put({ type: VIEW_GAMIFICATION_SUCCESS, payload: viewGamificationData.data });
			} else {
				yield put({
					type: VIEW_GAMIFICATION_FAILED,
					payload: viewGamificationData.data.message,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: VIEW_GAMIFICATION_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			}
		}
	}
}

function* postDeleteGamification({ payload }) {
	if (payload != null) {
		try {
			let deleteGamificationData = yield call(deleteGamificationApi, payload);
			let responseData = deleteGamificationData.data.data;

			if (
				deleteGamificationData.data.abstractResponse.responseStatus === "INQ000" &&
				responseData
			) {
				yield put({ type: DELETE_GAMIFICATION_SUCCESS, payload: responseData });
			} else {
				yield put({
					type: DELETE_GAMIFICATION_FAILED,
					payload: deleteGamificationData.data.message,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: DELETE_GAMIFICATION_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			} else {
				yield put({
					type: DELETE_GAMIFICATION_FAILED,
					payload: "Sorry, this feature is not accessible at this time.",
				});
			}
		}
	}
}

function* fetchGetGameTypes({ payload }) {
	if (payload) {
		try {
			const gameTypes = yield call(getGameTypesApi, payload);

			if (gameTypes) {
				yield put({
					type: GET_GAMIFICATION_TYPES_SUCCESS,
					payload: gameTypes.data,
				});
			} else {
				yield put({
					type: GET_GAMIFICATION_TYPES_FAILED,
					payload: gameTypes.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			if (error.response) {
				if (error.response) {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_GAMIFICATION_TYPES_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			}
		}
	}
}

function* fetchRewardGami({ payload }) {
	if (payload != null) {
		try {
			let rewardGamiData = yield call(getRewardPointsApi, payload);
			if (rewardGamiData.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: GET_REWARD_SUCCESS, payload: rewardGamiData.data });
			} else {
				yield put({
					type: GET_REWARD_FAILED,
					payload: rewardGamiData.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: GET_REWARD_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			}
		}
	}
}

function* fetchCreateGami({ payload }) {
	if (payload != null) {
		try {
			let createGamiData = yield call(createGamiApi, payload);
			if (createGamiData.data.abstractResponse.responseStatus === "INQ000") {
				yield put({ type: CREATE_GAMIFICATION_SUCCESS, payload: createGamiData.data });
			} else {
				yield put({
					type: CREATE_GAMIFICATION_FAILED,
					payload: createGamiData.data.abstractResponse.responseMessage,
				});
			}
		} catch (error) {
			if (error.response !== undefined) {
				if (error.response.data.abstractResponse.responseStatus === "AUTH001") {
					yield put({
						type: BACK_TO_LOGIN,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				} else {
					yield put({
						type: CREATE_GAMIFICATION_FAILED,
						payload: error.response.data.abstractResponse.responseMessage,
					});
				}
			}
		}
	}
}

export function* searchGami() {
	yield takeEvery(SEARCH_GAMIFICATION, fetchSearchGami);
}

export function* viewGami() {
	yield takeEvery(VIEW_GAMIFICATION, fetchViewGami);
}

export function* removeGamification() {
	yield takeEvery(DELETE_GAMIFICATION, postDeleteGamification);
}

export function* getGameTypes() {
	yield takeEvery(GET_GAMIFICATION_TYPES, fetchGetGameTypes);
}

export function* getRewardPoints() {
	yield takeEvery(GET_REWARD, fetchRewardGami);
}

export function* getCreateGami() {
	yield takeEvery(CREATE_GAMIFICATION, fetchCreateGami);
}

// yield all
export default function* rootSaga() {
	yield all([
		fork(searchGami),
		fork(viewGami),
		fork(removeGamification),
		fork(getGameTypes),
		fork(getRewardPoints),
		fork(getCreateGami),
	]);
}
