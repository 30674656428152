import {
	SEARCH_GAMIFICATION,
	DELETE_GAMIFICATION,
	SEARCH_GAMIFICATION_SUCCESS,
	SEARCH_GAMIFICATION_FAILED,
	FILTER_SEARCH_GAMIFICATION,
	CLEAR_FILTER_SEARCH_GAMIFICATION,
	RESET_STATUS,
	VIEW_GAMIFICATION,
	VIEW_GAMIFICATION_SUCCESS,
	VIEW_GAMIFICATION_FAILED,
	DELETE_GAMIFICATION_SUCCESS,
	DELETE_GAMIFICATION_FAILED,
	GET_GAMIFICATION_TYPES,
	GET_GAMIFICATION_TYPES_SUCCESS,
	GET_GAMIFICATION_TYPES_FAILED,
	CREATE_GAMIFICATION_QUIZ,
	CREATE_GAMIFICATION_QUIZ_SUCCESS,
	CREATE_GAMIFICATION_QUIZ_FAILED,
	CREATE_GAMIFICATION_SURVEY,
	CREATE_GAMIFICATION_SURVEY_SUCCESS,
	CREATE_GAMIFICATION_SURVEY_FAILED,
	CREATE_GAMIFICATION_SPINNER,
	CREATE_GAMIFICATION_SPINNER_SUCCESS,
	CREATE_GAMIFICATION_SPINNER_FAILED,
	UPDATE_GAMIFICATION_QUIZ,
	UPDATE_GAMIFICATION_QUIZ_SUCCESS,
	UPDATE_GAMIFICATION_QUIZ_FAILED,
	UPDATE_GAMIFICATION_SURVEY,
	UPDATE_GAMIFICATION_SURVEY_SUCCESS,
	UPDATE_GAMIFICATION_SURVEY_FAILED,
	UPDATE_GAMIFICATION_SPINNER,
	UPDATE_GAMIFICATION_SPINNER_SUCCESS,
	UPDATE_GAMIFICATION_SPINNER_FAILED,
	GET_REWARD,
	GET_REWARD_SUCCESS,
	GET_REWARD_FAILED,
	CREATE_GAMIFICATION,
	CREATE_GAMIFICATION_SUCCESS,
	CREATE_GAMIFICATION_FAILED,
} from "constants/ActionTypes";

const INIT_STATE = {
	loader: false,
	alertMessage: "",
	showMessage: false,
	listGamification: [],
	recordInfo: {
		totalRecords: 0,
		page: 0,
		nrecords: 0,
	},
	filterAndSort: {
		pagination: null,
		filters: null,
		sorter: null,
		search: null,
	},
	viewData: null,
	// viewData: {
	// 	id: "",
	// 	merchantId: "",
	// 	type: {
	// 		id: "",
	// 		code: "",
	// 		name: "",
	// 	},
	// 	isActivated: false,
	// 	startDate: null,
	// 	endDate: null,
	// 	detail: {
	// 		id: "",
	// 		title: "",
	// 		questions: [],
	// 		reward: {
	// 			id: "",
	// 			type: {
	// 				id: "",
	// 				code: "",
	// 				name: "",
	// 				description: "",
	// 			},
	// 			merchantId: "",
	// 			name: "",
	// 			value: "",
	// 		},
	// 	},
	// },
	surveyGamification: {
		userId: "",
		merchantId: "",
		typeId: "",
		name: "",
		startDate: "",
		endDate: "",
		point: "",
		surverGamificationDetailsList: [
			{
				data: {
					question: "",
					options: [
						{
							value: "",
						},
						{
							value: "",
						},
						{
							value: "",
						},
					],
				},
			},
		],
	},
	quizGamification: {
		userId: "",
		merchantId: "",
		typeId: "",
		name: "",
		startDate: "",
		endDate: "",
		point: "",
		quizGamificationDetailsList: [
			{
				data: {
					question: "",
					options: [
						{
							right: "",
							value: "",
						},
						{
							right: "",
							value: "",
						},
						{
							right: "",
							value: "",
						},
					],
				},
			},
		],
	},
	spinnerGamification: {
		userId: "",
		merchantId: "",
		typeId: "",
		name: "",
		startDate: "",
		endDate: "",
		startTime1: "",
		endTime1: "",
		startTime2: "",
		endTime2: "",
		chance: "",
		spinnerGamificationDetailsList: [
			{
				data: {
					question: "",
					options: [
						{
							value: "",
						},
						{
							value: "",
						},
						{
							value: "",
						},
					],
				},
			},
		],
	},

	updateSuccess: false,
	updateFailed: false,
	updateData: {},

	createSuccess: false,
	createFailed: false,
	createData: {},

	deleteSuccess: false,
	deleteFailed: false,
	gameTypes: {
		loader: true,
		alertMessage: "",
		showMessage: false,
		data: [],
	},
	rewardPoints: {
		loader: true,
		alertMessage: "",
		showMessage: false,
		data: [],
		code: "",
	},
	createGame: {
		loader: false,
		alertMessage: "",
		showMessage: false,
		data: {},
		code: "",
	},
	updateGame: {
		loader: false,
		alertMessage: "",
		showMessage: false,
		data: {},
		code: "",
	},
	createGamif: {
		loader: false,
		alertMessage: "",
		showMessage: false,
		data: {},
		code: "",
	},
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case FILTER_SEARCH_GAMIFICATION: {
			return {
				...state,
				filterAndSort: action.payload,
			};
		}

		case CLEAR_FILTER_SEARCH_GAMIFICATION: {
			return {
				...state,
				filterAndSort: {
					pagination: null,
					filters: null,
					sorter: null,
					search: null,
				},
			};
		}

		case SEARCH_GAMIFICATION: {
			return {
				...state,
				loader: true,
				showMessage: false,
				alertMessage: "",
				listGamification: [],
				recordInfo: {},
			};
		}

		case DELETE_GAMIFICATION: {
			return {
				...state,
				loader: true,
				showMessage: false,
				alertMessage: "",
			};
		}

		case RESET_STATUS: {
			return {
				...state,
				updateSuccess: false,
				updateFailed: false,
				updateData: {
					gameId: "",
					gameName: "",
					gameType: "",
					detail: "",
				},
				createSuccess: false,
				createFailed: false,
				createData: {
					gameName: "",
					gameType: "",
					detail: "",
				},
				deleteSuccess: false,
				deleteFailed: false,
				createGame: {
					loader: false,
					alertMessage: "",
					showMessage: false,
					data: {},
					code: "",
				},
				updateGame: {
					loader: false,
					alertMessage: "",
					showMessage: false,
					data: {},
					code: "",
				},
				createGamif: {
					loader: false,
					alertMessage: "",
					showMessage: false,
					data: {},
					code: "",
				},
			};
		}

		// Response
		case SEARCH_GAMIFICATION_SUCCESS: {
			return {
				...state,
				loader: false,
				listGamification: action.payload.data,
				recordInfo: action.payload.recordInfo,
			};
		}

		case SEARCH_GAMIFICATION_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case VIEW_GAMIFICATION: {
			return {
				...state,
				loader: false,
				viewData: null
			};
		}

		case VIEW_GAMIFICATION_SUCCESS: {
			return {
				...state,
				loader: false,
				viewData: action.payload.data,
			};
		}

		case VIEW_GAMIFICATION_FAILED: {
			return {
				...state,
				loader: false,
				alertMessage: action.payload,
				showMessage: true,
			};
		}

		case DELETE_GAMIFICATION_SUCCESS: {
			return {
				...state,
				loader: false,
				deleteSuccess: true,
				deleteFailed: false,
				showMessage: false,
			};
		}

		case DELETE_GAMIFICATION_FAILED: {
			return {
				...state,
				loader: false,
				deleteSuccess: false,
				deleteFailed: true,
				showMessage: false,
				alertMessage: action.payload,
			};
		}

		case GET_GAMIFICATION_TYPES: {
			return {
				...state,
				gameTypes: {
					loader: true,
					data: [],
					showMessage: false,
					alertMessage: "",
				},
			};
		}

		case GET_GAMIFICATION_TYPES_SUCCESS: {
			return {
				...state,
				gameTypes: {
					loader: false,
					showMessage: false,
					alertMessage: "",
					data: action.payload.data,
				},
			};
		}

		case GET_GAMIFICATION_TYPES_FAILED: {
			return {
				...state,
				gameTypes: {
					loader: false,
					showMessage: true,
					alertMessage: action.payload.errors,
					data: [],
				},
			};
		}

		case CREATE_GAMIFICATION: {
			return {
				...state,
				createGamif: {
					loader: true,
					data: {},
					showMessage: false,
					alertMessage: "",
					code: "",
				},
			};
		}

		case CREATE_GAMIFICATION_SUCCESS: {
			return {
				...state,
				createGamif: {
					loader: false,
					data: action.payload,
					showMessage: false,
					alertMessage: "",
					code: "",
				},
			};
		}

		case CREATE_GAMIFICATION_FAILED: {
			return {
				...state,
				createGamif: {
					loader: false,
					data: {},
					showMessage: true,
					alertMessage: action.payload.message,
					code: action.payload.code,
				},
			};
		}

		case CREATE_GAMIFICATION_QUIZ: {
			return {
				...state,
				createGame: {
					loader: true,
					data: {},
					showMessage: false,
					alertMessage: "",
					code: "",
				},
			};
		}

		case CREATE_GAMIFICATION_QUIZ_SUCCESS: {
			return {
				...state,
				createGame: {
					loader: false,
					data: action.payload,
					showMessage: false,
					alertMessage: "",
					code: action.payload.code,
				},
			};
		}

		case CREATE_GAMIFICATION_QUIZ_FAILED: {
			return {
				...state,
				createGame: {
					loader: false,
					data: {},
					showMessage: false,
					alertMessage: action.payload.message,
					code: action.payload.code,
				},
			};
		}

		case UPDATE_GAMIFICATION_QUIZ: {
			return {
				...state,
				updateGame: {
					loader: true,
					data: {},
					showMessage: false,
					alertMessage: "",
				},
			};
		}

		case UPDATE_GAMIFICATION_QUIZ_SUCCESS: {
			return {
				...state,
				updateGame: {
					loader: false,
					data: action.payload.data,
					showMessage: false,
					alertMessage: "",
					code: action.payload.code,
				},
			};
		}

		case UPDATE_GAMIFICATION_QUIZ_FAILED: {
			return {
				...state,
				updateGame: {
					loader: false,
					data: {},
					showMessage: true,
					alertMessage: action.payload,
				},
			};
		}

		case CREATE_GAMIFICATION_SURVEY: {
			return {
				...state,
				createGame: {
					loader: true,
					data: {},
					showMessage: false,
					alertMessage: "",
					code: "",
				},
			};
		}

		case CREATE_GAMIFICATION_SURVEY_SUCCESS: {
			return {
				...state,
				createGame: {
					loader: false,
					data: action.payload.data,
					showMessage: false,
					alertMessage: "",
					code: action.payload.code,
				},
			};
		}

		case CREATE_GAMIFICATION_SURVEY_FAILED: {
			return {
				...state,
				createGame: {
					loader: false,
					data: {},
					showMessage: true,
					alertMessage: action.payload.message,
					code: action.payload.code,
				},
			};
		}

		case UPDATE_GAMIFICATION_SURVEY: {
			return {
				...state,
				updateGame: {
					loader: true,
					data: {},
					showMessage: false,
					alertMessage: "",
					code: "",
				},
			};
		}

		case UPDATE_GAMIFICATION_SURVEY_SUCCESS: {
			return {
				...state,
				updateGame: {
					loader: false,
					data: action.payload,
					showMessage: false,
					alertMessage: "",
					code: action.payload.code,
				},
			};
		}

		case UPDATE_GAMIFICATION_SURVEY_FAILED: {
			return {
				...state,
				updateGame: {
					loader: false,
					data: {},
					showMessage: true,
					alertMessage: action.payload.message,
					code: action.payload.code,
				},
			};
		}

		case CREATE_GAMIFICATION_SPINNER: {
			return {
				...state,
				createGame: {
					loader: true,
					data: {},
					showMessage: false,
					alertMessage: "",
				},
			};
		}

		case CREATE_GAMIFICATION_SPINNER_SUCCESS: {
			return {
				...state,
				createGame: {
					loader: false,
					data: action.payload,
					showMessage: false,
					alertMessage: "",
				},
			};
		}

		case CREATE_GAMIFICATION_SPINNER_FAILED: {
			return {
				...state,
				createGame: {
					loader: false,
					data: {},
					showMessage: false,
					alertMessage: action.payload.errors,
				},
			};
		}

		case UPDATE_GAMIFICATION_SPINNER: {
			return {
				...state,
				updateGame: {
					loader: true,
					data: {},
					showMessage: false,
					alertMessage: "",
				},
			};
		}

		case UPDATE_GAMIFICATION_SPINNER_SUCCESS: {
			return {
				...state,
				updateGame: {
					loader: false,
					data: action.payload,
					showMessage: false,
					alertMessage: "",
				},
			};
		}

		case UPDATE_GAMIFICATION_SPINNER_FAILED: {
			return {
				...state,
				updateGame: {
					loader: false,
					data: {},
					showMessage: false,
					alertMessage: action.payload.errors,
				},
			};
		}

		case GET_REWARD: {
			return {
				...state,
				rewardPoints: {
					loader: true,
					data: [],
					showMessage: false,
					alertMessage: "",
				},
			};
		}

		case GET_REWARD_SUCCESS: {
			return {
				...state,
				rewardPoints: {
					loader: false,
					showMessage: false,
					alertMessage: "",
					data: action.payload.data,
				},
			};
		}

		case GET_REWARD_FAILED: {
			return {
				...state,
				rewardPoints: {
					loader: false,
					showMessage: true,
					alertMessage: action.payload.message,
					data: [],
				},
			};
		}

		default:
			return state;
	}
};
