import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
    RESET_STATUS,
    BACK_TO_LOGIN,
    INQ_ADDPOINT,
    INQ_ADDPOINT_SUCCESS,
    INQ_ADDPOINT_FAILED,
    POST_ADDPOINT,
    POST_ADDPOINT_SUCCESS,
    POST_ADDPOINT_FAILED
} from "constants/ActionTypes";
import {
    inquiryOrPostingAddPointApi
} from "../../appRedux/api/AddPoint";

function* postInquiryAddPoint({payload}) {
    if(payload != null){
        try {
            yield put({type: RESET_STATUS});
            let inqAddPointData = yield call(inquiryOrPostingAddPointApi, payload);
            if (inqAddPointData.data.abstractResponse.responseStatus === 'INQ000') {
                yield put({type: INQ_ADDPOINT_SUCCESS, payload: inqAddPointData.data});
            } else {
                yield put({type: INQ_ADDPOINT_FAILED, payload: inqAddPointData.data});
            }
        } catch (error) {
            if(error.response !== undefined) {
                if (error.response.data.abstractResponse.responseStatus === 'AUTH001') {
                    yield put({type: BACK_TO_LOGIN, payload: error.response.data.abstractResponse.responseMessage});
                } else {
                    yield put({
                        type: INQ_ADDPOINT_FAILED,
                        payload: error.response.data.abstractResponse.responseMessage
                    });
                }
            }else{
                yield put({
                    type: INQ_ADDPOINT_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

function* postAddPoint({payload}) {
    if(payload != null){
        try {
            yield put({type: RESET_STATUS});
            let postAddPointData = yield call(inquiryOrPostingAddPointApi, payload);
            if (postAddPointData.data.abstractResponse.responseStatus === 'INQ001') {
                yield put({type: POST_ADDPOINT_SUCCESS, payload: postAddPointData.data});
            }  else if (postAddPointData.data.abstractResponse.responseStatus === 'ERROR32') {
                yield put({type: POST_ADDPOINT_FAILED, payload: postAddPointData.data.abstractResponse.responseMessage});
            } else {
                yield put({type: POST_ADDPOINT_FAILED, payload: postAddPointData.data});
            }
        } catch (error) {
            if(error.response !== undefined) {
                if (error.response.data.abstractResponse.responseStatus === 'AUTH001') {
                    yield put({type: BACK_TO_LOGIN, payload: error.response.data.abstractResponse.responseMessage});
                } else {
                    yield put({
                        type: POST_ADDPOINT_FAILED,
                        payload: error.response.data.abstractResponse.responseMessage
                    });
                }
            }else{
                yield put({
                    type: POST_ADDPOINT_FAILED,
                    payload: 'Sorry, this feature is not accessible at this time.'
                });
            }
        }
    }
}

export function* inquiryAddPoint(){
    yield takeEvery(INQ_ADDPOINT, postInquiryAddPoint);
}

export function* postsAddPoint(){
    yield takeEvery(POST_ADDPOINT, postAddPoint);
}

// yield all
export default function* rootSaga() {
    yield all([
        fork(inquiryAddPoint),
        fork(postsAddPoint),
    ]);
}
